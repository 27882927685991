<template>
  <div class="footer">
    <div class="container">
      <div class="desc-row">
        <div class="item">
          <b>友情链接：</b>
          <a
            v-for="(item, index) in linkList"
            :key="index"
            @click="godetails(item.imgurl)"
          >
            {{ item.name }}
            <a-divider v-if="index !== linkList.length - 1" type="vertical"
          /></a>
        </div>
        <div class="item">
          <b>战略合作：</b>
          <a
            v-for="(item, index) in dataList"
            :key="index"
            @click="godetails(item.imgurl)"
          >
            {{ item.name }}
            <a-divider v-if="index !== dataList.length - 1" type="vertical"
          /></a>
        </div>
        <div class="item" :style="{ paddingBottom: '0px' }">
          <b>联系我们：</b>
          <span>{{connact.phone}}</span>
          <a-divider type="vertical" />
          <span>{{connact.email}}</span>
          <a-divider type="vertical" />
          <span>{{connact.address}}</span>
          <a-divider type="vertical" />
          <span>{{connact.postcode}}</span>
        </div>
      </div>
      <!-- <a-divider type="vertical" /> -->
      <div class="copyright">
        <span class="copyright-span">© {{copyright.copyrightyear}}</span>
        <span class="copyright-span">{{copyright.host}}</span>
        <span class="copyright-span"><a href="https://beian.miit.gov.cn" target="_blank">{{copyright.copyrightnumber}}</a></span>
        <span class="copyright-span">{{copyright.police}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/newsList.js";
import * as apifooter from "@/services/footer.js";
export default {
  name: "Footer",
  data() {
    return {
      linkList: [],
      dataList: [],
      connact: {},
      copyright: {},
    };
  },
  created() {
    //查询友情链接
    this.getAddList("2");
    this.getAddList("3");
    //查询联系我们信息
    this.getInfo("connact");
    this.getInfo("copyright");
  },
  methods: {
    getInfo(id) {
      apifooter.getInfo(id).then((res) => {
        if (res.data.success) {
          if (id === "connact") {
            this.connact = res.data.data;
          } else {
            this.copyright = res.data.data;
          }
        }
      });
    },
    godetails(url) {
      window.open(url);
    },
    getAddList(type) {
      let obj = {
        type: type,
        pageNumber: 1,
        pageSize: 100,
      };
      api.getAddList(obj).then((res) => {
        if (res.data.success) {
          if (type === "2") {
            //友情链接
            this.linkList = res.data.data.list;
          } else {
            this.dataList = res.data.data.list;
          }
        } else {
          this.linkList = [];
          this.dataList = [];
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  @media screen and (min-width: 1680px) {
    .container {
      width: 1560px !important;
    }
  }
  .container {
    font-size: 19px;
    display: flex;
    .desc-row{
      flex: 1;
      display: inline-block;
      margin-right: 70px;
    }
    .item {
      text-align: justify;
      b {
        font-size: 19px;
        margin-right: 0;
      }
      b, span, a{
        color: #333;
      }
      .ant-divider{
        background-color: #333;
      }
      a:hover{
        color: #cf4538;
      }
    }
    .copyright{
      // width: 25%;
      display: flex;
      flex-direction: column;
      // align-items: flex-end;
      justify-content: center;
      margin-left: 70px;
      // font-size: 16px;
      position: relative;
      .copyright-span{
        display: inline-block;
        text-align: center;
        color: #333;
        a:hover{
          color: #cf4538;
        }
      }
      &::before{
        display: inline-block;
        content: " ";
        width: 1px;
        background-color: #999;
        position: absolute;
        left: -70px;
        top: 15px;
        bottom: 15px;
      }
    }
  }
  .align-cener{
    text-align: center;
  }
}
</style>
